@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.playerPanel {
    @include flex-complete($direction: row, $justify: space-around, $align: flex-start);
}

.message {
    color: blue;
}

.getPlayer {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);
    padding: 8px;
    input {
        padding: 4px;
        margin-right: 8px;
    }

    button {
        padding: 4px;
    }
}

.form {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);
    img {
        width: 150px;
        margin-top: 8px;
        border: 2px solid black;
    }
    input {
        margin-top: 8px;
    }
    span {
        margin-top: 8px;
    }
    margin: 20px;
}
