@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.playerPanel {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);
}

.message {
    color: blue;
}

.results {
    @include flex-complete($direction: row, $justify: flex-start, $align: flex-start);
    padding: 8px;
}

.getPlayer {
    padding: 8px;
    input {
        padding: 4px;
        margin-right: 8px;
    }

    button {
        padding: 4px;
    }
}

.playerInfoForm {
    padding: 8px;
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);
    width: 350px;
    input {
        padding: 4px;
        margin-left: 8px;
        min-width: 200px;
    }
    label {
        margin-bottom: 8px;
    }
    button {
        margin: 8px;
        padding: 8px;
        align-self: flex-end;
        width: 150px;
    }

    .deletePlayerButton {
        background-color: red;
        color: white;
    }

    .warning {
        color: red;
    }
}
