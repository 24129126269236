@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.teamHero {
    @include flex-complete($direction: row, $justify: flex-start, $align: flex-start);
    padding: 16px;

    .teamHeroContent {
        max-width: 800px;
        & > div {
            @include flex-complete(
                $direction: column,
                $justify: flex-start,
                $align: flex-start
            );

            @media only screen and (min-width: $tablet) {
                @include flex-complete(
                    $direction: row,
                    $justify: flex-start,
                    $align: flex-start
                );
                padding: 16px;
            }

            img {
                width: 180px;
            }

            & > div {
                margin-left: 16px;
                p {
                    margin: 8px 0 8px 0;
                }
            }
        }
    }

    .extra {
        width: 300px;
    }
}
