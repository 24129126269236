@mixin flex-direction($direction: row) {
    flex-direction: $direction;
}

@mixin flex-justify($justify: space-between) {
    justify-content: $justify;
}

@mixin flex-align($align: center) {
    align-items: $align;
}

@mixin flex-complete($direction: row, $justify: space-between, $align: center) {
    display: flex;
    @include flex-direction($direction);
    @include flex-justify($justify);
    @include flex-align($align);
}
