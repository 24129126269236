@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.playerHero {
    @include flex-complete($direction: column, $justify: flex-start, $align: center);
    width: 100%;

    @media only screen and (min-width: $tablet) {
        @include flex-complete($direction: row, $justify: flex-start, $align: flex-start);
    }
    padding: 16px;

    .playerHeroContent {
        @include flex-complete($direction: column, $justify: flex-start, $align: center);
        width: 100%;

        @media only screen and (min-width: $tablet) {
            @include flex-complete(
                $direction: column,
                $justify: flex-start,
                $align: flex-start
            );
            max-width: 1000px;
        }

        h2 {
            margin-bottom: 12px;
            @media only screen and (min-width: $tablet) {
                margin-bottom: 0;
            }
        }

        h3 {
            margin-bottom: 12px;
            @media only screen and (min-width: $tablet) {
                margin-bottom: 0;
            }
        }

        h5 {
            margin-top: 20px;
            @media only screen and (min-width: $tablet) {
                margin-top: 0px;
                margin-left: 16px;
            }
        }

        ul.prizes {
            list-style-type: none;
            li {
                margin-top: 8px;
                background-color: $golden;
                border-radius: 4px;
                padding-left: 8px;
                padding-right: 8px;
            }
            @media only screen and (min-width: $tablet) {
                padding-left: 16px;
            }
        }

        & > div {
            @include flex-complete(
                $direction: column,
                $justify: flex-start,
                $align: center
            );
            width: 100%;

            @media only screen and (min-width: $tablet) {
                @include flex-complete(
                    $direction: row,
                    $justify: flex-start,
                    $align: flex-start
                );
                padding: 16px 0px 8px 0px;
                width: auto;
            }

            img {
                width: 140px;
                border: 2px black solid;
                box-shadow: $boxShadowSmall;
            }
        }

    }

    .extra {
        display: none;
        @media only screen and (min-width: $tablet) {
            display: flex;
            width: 300px;
        }
    }

    .birthday {
        padding: 4px;
        background-color: #f0d54f;
        margin-left: 0;
        margin-top: 8px;
        display: flex;
        justify-content: center;
        border-radius: 4px;
    }
}

.dataBlock {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);
    width: 100%;
    @media only screen and (min-width: $tablet) {
        width: auto;
        margin-left: 16px;
    }
    div {
        margin-top: 8px;
        @media only screen and (min-width: $tablet) {
            width: 100%;
            margin-left: 16px;
        }
        font-weight: bold;
        & > span {
            font-weight: normal;
            margin-left: 4px;
        }
    }

    &.datesBlock {
        @media only screen and (min-width: $tablet) {
            max-width: 300px;
        }
    }
}

div.commentBlock {
    @media only screen and (min-width: $tablet) {
        padding: 0 !important;
    }
}

.comment {
    align-self: flex-start;
    padding-top: 8px;
    max-width: 700px;
    span {
        font-weight: bold;
    }
}
