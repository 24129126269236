@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.signUpForm {
    @include flex-complete($direction: column, $justify: flex-start, $align: center);
    margin: 0 auto;

    h3 {
        padding: 8px;
    }

    input {
        padding: 8px;
        margin: 8px;
    }

    button {
        padding: 8px;
        margin: 4px;
        border: none;
        border-radius: 4px;
        background-color: $wbdBlue;
        color: white;
    }

    span.message {
        color: $wbdBlue;
        padding: 4px;
    }
}
