@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.usersPanel {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);

    button.getUsersButton {
        padding: 8px;
        border: none;
        border-radius: 4px;
        background-color: $wbdBlue;
        color: white;
        margin-right: 16px;
    }

    table {
        margin-top: 8px;

        tr {
            th {
                padding: 8px;
                background-color: $wbdBlue;
                color: white;
            }
            td {
                padding: 4px;
            }
        }
    }
}

.commandBlock {
    @include flex-complete($direction: row, $justify: flex-start, $align: flex-end);
}

.editing {
    padding: 8px;
    margin-left: 16px;
    color: $wbdBlue;
}

.filterBox {
    align-self: flex-end;
    input {
        padding: 8px;
    }
}

.DayPickerInput {
    input {
        width: 120px;
    }
    padding-left: 4px;
}

.datepicker_red {
    div.DayPickerInput {
        background-color: #ff0000;
    }
}
.datepicker_orange {
    div.DayPickerInput {
        background-color: #ffcc00;
    }
}
.datepicker_blue {
    div.DayPickerInput {
        background-color: #3399ff;
    }
}
