@import "../../../style/variables.scss";
@import "../../../style/mixins.scss";

.tableCell {
    background-color: white;
    color: black;
    padding: 4px 10px 4px 10px;
    border: 0px;
    margin: 0px;
    font-weight: normal;
    white-space: nowrap;
    z-index: 3;
}