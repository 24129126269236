//colors
$wbdRed: rgb(191, 0, 0);
$wbdBlue: rgb(7, 19, 79);
$wbdRedLight: rgb(189, 108, 108);
$wbdBlueLight: #4759b6;
$lightgray: #f3f4f5;
$darklightgray: #e5ecf3;
$darkerlightgray: #c7d2dd;
$namegray: rgb(211, 218, 226);
$golden: #fcefc5;

//sizes
$tablet: 764px;
$desktop: 1024px;

//effects

$boxShadowLarge: 6px 6px 12px 6px rgba(0, 0, 0, 0.2), 6px 6px 18px 6px rgba(0, 0, 0, 0.19);
$boxShadowSmall: 3px 3px 6px 3px rgba(0, 0, 0, 0.2), 3px 3px 12px 3px rgba(0, 0, 0, 0.19);
