@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.payLinks {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);
    padding: 16px;

    button {
        padding: 8px;
        margin-top: 16px;
    }
}
