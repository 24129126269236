@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.overlay {
    position: fixed;
    top: 0;
    left: -100%;
    background-color: gray;
    opacity: 0.5;
    width: 100%;
    height: 100vh;
    z-index: 99;

    transition: opacity 0.4s;

    @media only screen and (min-width: $tablet) {
        display: none;
    }
}

.overlay.show {
    left: 0;
}
