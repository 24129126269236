@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.notice {
    @include flex-complete($direction: column, $justify: flex-start, $align: center);
    width: 100%;
    padding: 16px;
    p {
        padding-bottom: 16px;
    }

    @media only screen and (min-width: $tablet) {
        @include flex-complete($direction: column, $justify: flex-start, $align: center);
    }
}
