@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.login {
    @include flex-complete($direction: column, $justify: flex-start, $align: center);

    a.createAccountLink {
        margin: 0 auto;
        padding-top: 4px;
        margin-top: 16px;
    }
    .spacer {
        height: 30px;
    }

    & > span {
        padding: 16px;
    }
}
