@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.settings {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);

    .content {
        & > div,
        h3 {
            padding: 16px;
        }

        button {
            padding: 8px;
            border: none;
            border-radius: 4px;
            background-color: $wbdRed;
            color: white;
        }
    }
}
