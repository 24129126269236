@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.home {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);

    .content {
        @include flex-complete($direction: column, $justify: flex-start, $align: center);
    }
}

.homeHero {
    @include flex-complete($direction: column, $justify: flex-start, $align: center);
    h3 {
        padding: 8px;
    }

    p {
        padding: 4px;
        margin-bottom: 16px;
    }

    a {
        background-color: $wbdBlue;
        color: white;
        border-radius: 8px;
        padding: 8px;
    }

    margin-bottom: 16px;

    @media only screen and (min-width: $tablet) {
        background-image: url("../../images/campobaseball.jpg");
        background-size: cover;
        background-color: white;
        color: white;
        width: 100%;
        img {
            width: 250px;
            margin: 16px;
        }
    }
}

.homeLinks {
    @include flex-complete($direction: column, $justify: flex-start, $align: center);
    a {
        margin-bottom: 8px;
        width: 200px;
        text-align: center;
    }
    @media only screen and (min-width: $tablet) {
        @include flex-complete($direction: row, $justify: flex-start, $align: flex-start);
        a {
            margin: 8px;
        }
    }
}

.infoBlock {
    @include flex-complete($direction: column, $justify: flex-start, $align: center);
    width: 320px;
    padding-top: 16px;
    img {
        width: 320px;
        margin: 16px;
        box-shadow: $boxShadowSmall;
    }
    h4 {
        margin-bottom: 8px;
    }
    @media only screen and (min-width: $tablet) {
        @include flex-complete($direction: row, $justify: space-evenly, $align: center);
        width: 750px;
        p {
            padding: 4px;
        }
        &.middleBlock {
            img {
                order: 1;
            }
            div {
                order: 2;
            }
        }
    }

    & > p {
        margin-bottom: 32px;
    }
}

.spacer {
    padding: 16px;
    img {
        width: 40px;
    }
    @media only screen and (min-width: $tablet) {
        padding: 32px;
    }
}

.subtitle {
    color: black;
    background-color: white;
    padding: 4px;
    border-radius: 4px;
}
