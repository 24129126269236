@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.read {
    @include flex-complete($direction: row, $justify: flex-start, $align: flex-start);

    .inputArea {
        @include flex-complete(
            $direction: column,
            $justify: flex-start,
            $align: flex-start
        );

        width: 400px;

        & > div {
            padding: 8px;
            margin: 8px;
            box-shadow: $boxShadowSmall;
            border-radius: 8px;
            width: 250px;
        }
    }

    .outputArea {
        @include flex-complete(
            $direction: column,
            $justify: flex-start,
            $align: flex-start
        );
        border: $wbdBlue 2px solid;
        border-radius: 8px;

        .output {
            min-height: 300px;
            min-width: 500px;
        }
    }
}

.get {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);
    button {
        min-width: 100px;
        margin: 4px;
    }
    input {
        padding: 4px;
        margin: 4px;
    }
}

.dataView {
    padding: 16px;
}
