@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.manage {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);
    width: 100%;
    & > div {
        padding: 8px;
    }

    h3 {
        padding: 12px;
    }
}

.tabs {
    width: 100%;
    @include flex-complete($direction: row, $justify: flex-start, $align: center);
    background-color: $wbdBlue;

    button {
        background-color: $wbdRed;
        padding: 8px;
        font-size: 16px;
        border-radius: 8px;
        border: none;
        color: white;
        margin-right: 40px;
        width: 100px;
    }
}

button.active {
    background-color: lightskyblue;
    color: black;
}
