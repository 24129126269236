@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.players {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);
    width: 100%;

    form {
        @include flex-complete(
            $direction: column,
            $justify: flex-start,
            $align: flex-start
        );
        width: 100%;
        background-color: $lightgray;

        div {
            padding: 8px;
        }

        input {
            width: 300px;
            margin: 8px;
            @media only screen and (min-width: $tablet) {
                width: 350px;
            }
        }

        ul {
            position: absolute;
            top: 0;
            z-index: 55;
            width: 300px;
            margin-left: 8px;
            margin-top: 115px;
            background-color: white;
            @media only screen and (min-width: $tablet) {
                margin-top: 110px;
                width: 350px;
            }
        }

        button {
            align-self: flex-end;
            margin-bottom: 8px;
            margin-right: 8px;
            padding: 8px;
            border: none;
            border-radius: 4px;
            background-color: $wbdBlue;
            color: white;
            @media only screen and (min-width: $tablet) {
                margin-right: 0;
            }
        }

        @media only screen and (min-width: $tablet) {
            @include flex-complete($direction: row, $justify: flex-start, $align: center);

            select {
                padding: 4px;
                margin: 8px;
            }

            button {
                align-self: center;
                padding: 4px;
                margin: 4px;
                margin-bottom: 4px;
                @media only screen and (min-width: $tablet) {
                    padding: 8px;
                    margin-left: 16px;
                }
            }
        }

        & > div {
            @include flex-complete($direction: row, $justify: flex-start, $align: center);
            & > div {
                width: 80px;
            }

            @media only screen and (min-width: $tablet) {
                @include flex-complete(
                    $direction: row,
                    $justify: flex-start,
                    $align: center
                );
                margin-left: 16px;
                & > div {
                    width: auto;
                }
            }
        }
    }
}

.content {
    width: 100%;
}

.noResults {
    padding: 16px;
    color: $wbdBlue;
}
