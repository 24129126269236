@import "../../../style/variables.scss";
@import "../../../style/mixins.scss";

.playerBat {
    @include flex-complete($direction: column, $justify: flex-start, $align: flex-start);
    max-width: 100%;

    padding: 16px;
    h3 {
        margin-bottom: 8px;
    }

    h5 {
        margin-bottom: 4px;
    }

    .tableContainer {
        max-width: 100%;
        overflow-x: scroll;
        margin-bottom: 20px;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
}

//these two classes apply to td and th
.x0 {
    position: sticky;
    left: 0;
    z-index: 2;
    min-width: 70px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 1px rgba(0, 0, 0, 0.19);
}

td.highlight {
    background-color: $darklightgray;
    transition: 0.3s;
}

.odd > td {
    background-color: $lightgray;
    &.highlight {
        background-color: $darkerlightgray;
    }
}

.tableRow:hover {
    background-color: yellow;
}

td.total {
    background-color: $wbdBlue;
    color: white;
}

td.special {
    background-color: $wbdBlueLight;
    max-width: 70px;
    overflow: visible;
}
