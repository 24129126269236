@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.upload {
    @include flex-complete($direction: row, $justify: flex-start, $align: flex-start);

    .inputArea {
        @include flex-complete(
            $direction: column,
            $justify: flex-start,
            $align: flex-start
        );

        width: 500px;

        & > div {
            padding: 8px;
            margin: 8px;
            box-shadow: $boxShadowSmall;
            border-radius: 8px;
            min-width: 400px;
        }

        span {
            padding: 8px;
        }
    }

    .outputArea {
        @include flex-complete(
            $direction: column,
            $justify: flex-start,
            $align: flex-start
        );
        border: $wbdBlue 2px solid;
        border-radius: 8px;

        .output {
            min-height: 300px;
            min-width: 500px;
        }
    }

    .message {
        color: blue;
    }
}

.firebase {
    background-color: rgb(226, 182, 100);
}
