@import "../../style/variables.scss";
@import "../../style/mixins.scss";

$fixedWidth: 180px;

.autocomplete {
    padding: 4px;
    width: $fixedWidth;
}

.suggestions {
    border: 1px solid $wbdBlue;
    border-top-width: 0;
    list-style: none;
    overflow-y: hidden;
    width: $fixedWidth;
    li {
        padding: 4px;
    }
}

.suggestion-active {
    background-color: $wbdBlue;
    color: white;
}
