@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.pricePlan {
    @include flex-complete($direction: column, $justify: flex-start, $align: center);

    @media only screen and (min-width: $tablet) {
        @include flex-complete($direction: row, $justify: space-evenly, $align: center);
        width: 90%;
    }

    @media only screen and (min-width: $desktop) {
        width: 60%;
    }
}

.plan {
    @include flex-complete($direction: column, $justify: flex-start, $align: center);
    box-shadow: $boxShadowSmall;
    border-radius: 8px;
    margin-bottom: 24px;
    width: 225px;
    // transition: width 0.5s;
    // &:hover{
    //     width: 230px;
    // }

    .info {
        @include flex-complete($direction: column, $justify: flex-start, $align: center);
        min-height: 120px;
        h3 {
            padding: 8px;
        }

        p {
            text-align: center;
            padding: 4px 8px 8px 8px;
        }
    }

    .priceSection {
        @include flex-complete($direction: column, $justify: flex-start, $align: center);
        background-color: $wbdBlue;
        width: 100%;
        padding: 8px;
        padding-bottom: 16px;
        border-radius: 0 0 8px 8px;

        div {
            padding: 8px;
            font-size: 18px;
            font-weight: bold;
            color: white;
        }

        a {
            padding: 8px;
            background-color: $wbdRed;
            color: white;
            border-radius: 8px;
        }
    }
}
