@import "../../style/variables.scss";
@import "../../style/mixins.scss";

.docs {
    @include flex-complete($direction: row, $justify: flex-start, $align: flex-start);

    .docsBlock {
        @include flex-complete(
            $direction: column,
            $justify: flex-start,
            $align: flex-start
        );

        padding: 8px;
        margin: 8px;
        box-shadow: $boxShadowSmall;
        border-radius: 8px;
        width: 800px;
        
    }
}